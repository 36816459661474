import axios from '@axios'
import useJwt from '@/auth/jwt/useJwt'

export default {
  namespaced: true,
  state: {
    priorityList: {},
    priorityOptions: [],
    statusList: {},
    statusOptions: [],
    roleLists: {},
    roleOptions: [],
    groupOptions: [],
    // todoView: JSON.parse(localStorage.getItem('todo-view')) ? JSON.parse(localStorage.getItem('todo-view')) : {},
    firstListInfo: {
      id: null,
      total: 0,
    },
    quickOptions: [],
    useAudioState: null,
    useQuickState: false,
    metaList: {
      admin_brands: [],
      brands: [],
      departments: [],
      execution_types: [],
      groups: [],
      places: [],
      priority: [],
      todo_role: [],
      todo_status: [],
      todo_types: [],
    },
  },
  getters: {},
  mutations: {
    UPDATE_PRIORITY_LIST(state, val) {
      state.priorityList = val
    },
    UPDATE_PRIORITY_OPTIONS(state, val) {
      state.priorityOptions = val
    },
    UPDATE_STATUS_LIST(state, val) {
      state.statusList = val
    },
    UPDATE_STATUS_OPTIONS(state, val) {
      state.statusOptions = val
    },
    UPDATE_ROLE_LIST(state, val) {
      state.roleLists = val
    },
    UPDATE_ROLE_OPTIONS(state, val) {
      state.roleOptions = val
    },
    UPDATE_GROUP_OPTIONS(state, val) {
      state.groupOptions = val
    },
    UPDATE_FIRST_LIST_INFO(state, val) {
      state.firstListInfo = val
    },
    UPDATE_QUICK_LIST_OPTIONS(state, val) {
      state.quickOptions = val
    },
    UPDATE_USE_AUDIO_STATE(state, val) {
      state.useAudioState = val
    },
    UPDATE_USE_QUICK_STATE(state, val) {
      state.useQuickState = val
    },
  },
  actions: {
    // (查詢)待辦列表
    getTodoList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/todo', { headers: { 'Access-Token': useJwt.getToken() }, params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (查詢)單一待辦
    getTodoData(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/admin/todo/${resolveData.todo_id}`, { headers: { 'Access-Token': useJwt.getToken() }, params: resolveData.queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (新增)待辦
    setTodoCreate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios('/admin/todo', {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (快速新增)待辦
    setTodoCreateQuick(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios('/admin/todo/quick', {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (更新)待辦
    setTodoUpdate(ctx, resolveData) {
      if (resolveData.todo_id) {
        return new Promise((resolve, reject) => {
          axios(`/admin/todo/${resolveData.todo_id}`, {
            method: 'PATCH',
            headers: {
              'Access-Token': useJwt.getToken(),
            },
            data: {
              ...resolveData.data,
            },
          })
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      }
      return new Promise((resolve, reject) => {
        axios('/admin/todo', {
          method: 'PATCH',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData.data,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (刪除)待辦
    setTodoDelete(ctx, resolveData) {
      if (resolveData.todo_id) {
        return new Promise((resolve, reject) => {
          axios(`/admin/todo/${resolveData.todo_id}`, {
            method: 'DELETE',
            headers: {
              'Access-Token': useJwt.getToken(),
            },
          })
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      }
      return new Promise((resolve, reject) => {
        axios('/admin/todo', {
          method: 'DELETE',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData.data,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (完成)待辦
    setTodoComplete(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios('/admin/todo/complete', {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData.data,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (複查)待辦
    setTodoReview(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios('/admin/todo/review', {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData.data,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (快取)暫存文字
    setTodoCache(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/todo/${resolveData.todo_id}/message/cache`, {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData.data,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (標記)待辦為已讀/未讀
    setTodoViewUpdate(ctx, resolveData) {
      if (resolveData.type === 'unread') {
        // 標記未讀
        return new Promise((resolve, reject) => {
          axios('/admin/todo/view', {
            method: 'POST',
            headers: {
              'Access-Token': useJwt.getToken(),
            },
            data: {
              ...resolveData.data,
            },
          })
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      }
      // 標記已讀
      return new Promise((resolve, reject) => {
        axios('/admin/todo/view', {
          method: 'DELETE',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData.data,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // -------------------------------------------------------------------------------------------
    // (上傳)待辦附件
    setTodoAppendUpload(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/todo/${resolveData.todo_id}/appends`, {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: resolveData.data,
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (更新)待辦附件
    setTodoAppendUpdate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/todo/${resolveData.todo_id}/appends/${resolveData.append_id}`, {
          method: 'PATCH',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData.data,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (刪除)待辦附件
    setTodoAppendDelete(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/todo/${resolveData.todo_id}/appends/${resolveData.append_id}`, {
          method: 'DELETE',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // -------------------------------------------------------------------------------------------
    // (查詢)待辦備註列表
    getTodoRemarkList(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/admin/todo/${resolveData.todo_id}/remarks`, { headers: { 'Access-Token': useJwt.getToken() }, params: resolveData.queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (查詢)待辦搜尋
    getTodoRemarkSearch(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/admin/todo/${resolveData.todo_id}/remarks/search`, { headers: { 'Access-Token': useJwt.getToken() }, params: resolveData.queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (新增)待辦備註
    setTodoRemarkCreate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/todo/${resolveData.todo_id}/remarks`, {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData.data,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (更新)待辦備註
    setTodoRemarkUpdate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/todo/${resolveData.todo_id}/remarks/${resolveData.remark_id}`, {
          method: 'PATCH',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData.data,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (刪除)待辦備註
    setTodoRemarkDelete(ctx, resolveData) {
      if (resolveData.type === 'muti') {
        return new Promise((resolve, reject) => {
          axios(`/admin/todo/${resolveData.todo_id}/remarks`, {
            method: 'DELETE',
            headers: {
              'Access-Token': useJwt.getToken(),
            },
            data: {
              ...resolveData.data,
            },
          })
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      }
      return new Promise((resolve, reject) => {
        axios(`/admin/todo/${resolveData.todo_id}/remarks/${resolveData.remark_id}`, {
          method: 'DELETE',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData.data,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // -------------------------------------------------------------------------------------------

    // (查詢)備註詳情
    getRemarkAppendData(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/admin/todo/remarks/${resolveData.remark_id}/appends`, { headers: { 'Access-Token': useJwt.getToken() }, params: resolveData.queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (上傳)備註檔案
    setRemarkAppendUpload(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/todo/remarks/${resolveData.remark_id}/appends`, {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: resolveData.data,
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (獲取)備註檔案Key
    getRemarkAppendDownloadKey(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/admin/todo/remarks/${resolveData.remark_id}/appends/${resolveData.append_id}`, { headers: { 'Access-Token': useJwt.getToken() }, params: resolveData.queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // -------------------------------------------------------------------------------------------

    // (查詢)群組權限列表
    getTodoGroupList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/todo/group', { headers: { 'Access-Token': useJwt.getToken() }, params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (查詢)群組權限列表
    getTodoGroupData(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/admin/todo/group/${resolveData.group_id}`, { headers: { 'Access-Token': useJwt.getToken() }, params: resolveData.queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (更新)群組使用者權限
    setTodoGroupUserData(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/todo/group/${resolveData.group_id}/user`, {
          method: 'PATCH',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData.data,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (新增)群組權限
    setTodoGroupCreate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios('/admin/todo/group', {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (更新)群組權限
    setTodoGroupUpdate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/todo/group/${resolveData.group_id}`, {
          method: 'PATCH',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData.data,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (刪除)群組權限
    setTodoGroupDelete(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/todo/group/${resolveData.group_id}`, {
          method: 'DELETE',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData.data,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // -------------------------------------------------------------------------------------------

    // (查詢)作業分類列表
    getTodoTypeList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/todo/type', { headers: { 'Access-Token': useJwt.getToken() }, params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (新增)作業分類
    setTodoTypeCreate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios('/admin/todo/type', {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (更新)作業分類
    setTodoTypeUpdate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/todo/type/${resolveData.type_id}`, {
          method: 'PATCH',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData.data,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (刪除)作業分類
    setTodoTypeDelete(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/todo/type/${resolveData.type_id}`, {
          method: 'DELETE',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData.data,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // -------------------------------------------------------------------------------------------
    // (查詢)執行作業分類列表
    getTodoExecutionList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/todo/execution-type', { headers: { 'Access-Token': useJwt.getToken() }, params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (新增)執行作業分類
    setTodoExecutionCreate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios('/admin/todo/execution-type', {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (更新)執行作業分類
    setTodoExecutionUpdate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/todo/execution-type/${resolveData.type_id}`, {
          method: 'PATCH',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData.data,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (刪除)執行作業分類
    setTodoExecutionDelete(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/todo/execution-type/${resolveData.type_id}`, {
          method: 'DELETE',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData.data,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // -------------------------------------------------------------------------------------------
    // (查詢)待辦設定
    getTodoConfigList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/todo/config', { headers: { 'Access-Token': useJwt.getToken() }, params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (新增)待辦設定
    setTodoConfigCreate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios('/admin/todo/config', {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (更新)待辦設定
    setTodoConfigUpdate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/todo/config/${resolveData.config_id}`, {
          method: 'PATCH',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData.data,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (刪除)待辦設定
    setTodoConfigDelete(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/todo/config/${resolveData.config_id}`, {
          method: 'DELETE',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData.data,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // -------------------------------------------------------------------------------------------
    // (查詢)部門統計
    getLineChartDepartmentList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/todo/analytics', { headers: { 'Access-Token': useJwt.getToken() }, params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // -------------------------------------------------------------------------------------------
    // (查詢)客戶資料
    getCustomerList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/customer', { headers: { 'Access-Token': useJwt.getToken() }, params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (查詢)單一客戶資料
    getCustomerData(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/admin/customer/${resolveData.customer_id}`, { headers: { 'Access-Token': useJwt.getToken() }, params: resolveData.queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (新增)客戶資料
    setTodoCustomerCreate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios('/admin/customer', {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (更新)客戶資料
    setTodoCustomerUpdate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/customer/${resolveData.customer_id}`, {
          method: 'PATCH',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData.data,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
