import { ref, watch, computed } from '@vue/composition-api'
import {
  useTableComponent, useCommenSettings, useAlert,
} from '@/libs/mixins/index'
import store from '@/store'
// import router from '@/router'

export const useTodoSetting = () => {
  const {
    syncObject,
    syncObjectDeep,
    refonlineTime,
    onlineTime,
    updateOnline,
  } = useCommenSettings()

  const ui = {
    groupPublic: [{
      true: '啟用', false: '關閉',
    }, {
      true: 'light-success', false: 'light-secondary',
    }],
  }

  const publicOptions = [{
    label: '關閉', value: false,
  }, {
    label: '啟用', value: true,
  }]

  const searchDepartment = ref(null)
  const departmentsOptions = ref([])
  const adminBrandOptions = ref([])
  const typeOptions = ref([])
  const executionTypeOptions = ref([])
  const typeGroupOptions = ref([])
  const executionTypeGroupOptions = ref([])
  const priorityOptions = ref([])
  const todoStatusOptions = ref([])
  const personalQuickOptions = ref([])
  const roleLists = ref({})
  const roleOptions = ref([])

  const customerListOptions = ref([])

  const getMetaListData = () => store.dispatch('api/getMetaList')
  const getConfigData = (...arg) => store.dispatch('api/getConfigData', ...arg)
  const getCustomerListData = (...arg) => store.dispatch('admin-todo/getCustomerList', ...arg)
  const setTodoCustomerCreate = (...arg) => store.dispatch('admin-todo/setTodoCustomerCreate', ...arg)
  const setTodoCustomerUpdate = (...arg) => store.dispatch('admin-todo/setTodoCustomerUpdate', ...arg)
  const getCustomerData = (...arg) => store.dispatch('admin-todo/getCustomerData', ...arg)

  const blankDepartmentInfo = {
    id: null,
    name: null,
  }

  const blankSimpleInfo = {
    id: null,
    name: null,
  }

  const blankDescriptionInfo = {
    id: null,
    name: null,
    description: '',
  }

  const blankChartData = {
    series: [0],
    options: {
      grid: {
        show: false,
        padding: {
          left: -15,
          right: -15,
          top: -12,
          bottom: -15,
        },
      },
      colors: ['#24c9ff'],
      plotOptions: {
        radialBar: {
          hollow: {
            size: '22%',
          },
          track: {
            background: '#e9ecef',
          },
          dataLabels: {
            showOn: 'always',
            name: {
              show: false,
            },
            value: {
              show: false,
            },
          },
        },
      },
      stroke: {
        lineCap: 'round',
      },
    },
  }

  const blankClassData = {
    department_id: null,
    name: null,
    description: '',
  }

  const blankCustomerData = {
    id: null,
    name: null,
    email: null,
    image: null,
    admin_brand_id: null,
    admin_brand: {
      id: null,
      name: null,
      description: null,
      icon: null,
    },
  }

  const resolveChartDataColor = health => {
    if (health <= 10) return '#ff5b52'
    if (health <= 50) return '#ffbb53'
    if (health < 100) return '#24c9ff'
    if (health === 100) return '#37deb2'
    return '#24c9ff'
  }

  return {
    ui,

    syncObject,
    syncObjectDeep,
    refonlineTime,
    onlineTime,
    updateOnline,
    getMetaListData,
    getConfigData,
    getCustomerData,
    getCustomerListData,
    setTodoCustomerCreate,
    setTodoCustomerUpdate,
    searchDepartment,
    departmentsOptions,
    adminBrandOptions,
    executionTypeOptions,
    typeOptions,
    typeGroupOptions,
    executionTypeGroupOptions,
    priorityOptions,
    todoStatusOptions,
    personalQuickOptions,
    roleLists,
    roleOptions,
    publicOptions,
    customerListOptions,

    blankDepartmentInfo,
    blankChartData,
    blankClassData,
    blankSimpleInfo,
    blankDescriptionInfo,
    blankCustomerData,

    resolveChartDataColor,
  }
}

export const useQuickList = () => {
  const tableData = ref([])
  const isTableBusy = ref(true)

  const {
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
  } = useTableComponent()

  const {
    useHttpCodeAlert,
  } = useAlert()

  tableColumns.value = [
    { label: '排序', key: 'id' },
    { label: '名稱', key: 'name' },
    { label: '動作', key: 'actions' },
  ]

  const setTodoConfigDelete = (...arg) => store.dispatch('admin-todo/setTodoConfigDelete', ...arg)
  const setTodoConfigCreate = (...arg) => store.dispatch('admin-todo/setTodoConfigCreate', ...arg)
  const setTodoConfigUpdate = (...arg) => store.dispatch('admin-todo/setTodoConfigUpdate', ...arg)

  const getTodoConfigList = () => {
    isTableBusy.value = true
    store.dispatch('admin-todo/getTodoConfigList')
      .then(response => {
        const { data } = response.data
        tableData.value = data
        // totalNum.value = total
        isTableBusy.value = false
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  const configData = ref(null)

  const blankConfigData = {
    id: null,
    name: null,
    order: 0,
    content: {},
  }

  const blankContentData = {
    group_id: null,
    admin_brand_id: null,
    department_id: null,
    title: null,
    type_id: null,
    execution_type_id: null,
    status: 'not-completed',
    priority: 50,
    client_name: null,
    customer_id: null,
    customer: null,
    // customer_id: null,
    display_status: true,
    scheduled_at: null, // formatDate(new Date()),
    deadline_at: null,
  }

  const blankContentSetting = {
    show_title: false,
    show_client_name: false,
    show_priority: false,
    show_group_id: false,
    show_scheduled_at: false,
    set_scheduled_at: '1h',
    show_deadline_at: false,
    set_deadline_at: '1h',
  }

  const refetchTable = () => {
    getTodoConfigList()
  }

  return {
    tableData,
    isTableBusy,
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    refetchTable,

    setTodoConfigDelete,
    setTodoConfigCreate,
    setTodoConfigUpdate,
    getTodoConfigList,
    useHttpCodeAlert,

    configData,
    blankContentSetting,
    blankContentData,
    blankConfigData,
  }
}

export const useSearchList = () => {
  const tableData = ref([])
  const isTableBusy = ref(true)

  const searchOptions = [
    { label: '#ID', value: 'id' },
    { label: '更新時間', value: 'updated_at' },
    { label: '優先程度', value: 'priority' },
    { label: '創建時間', value: 'created_at' },
    { label: '完成時間', value: 'completed_at' },
    { label: '複查時間', value: 'review_at' },
  ]

  const {
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
  } = useTableComponent()

  const {
    useHttpCodeAlert,
  } = useAlert()

  const {
    getConfigData,
  } = useTodoSetting()

  tableColumns.value = [
    { label: '序號', key: 'id' },
    { label: '名稱', key: 'name' },
    { label: '動作', key: 'actions' },
  ]

  const setConfigUpdate = (...arg) => store.dispatch('api/setConfigUpdate', ...arg)

  const initSearchData = {
    useAudio: false,
    useQuick: false,
    useFullScreen: false,
    // sortBy: 'updated_at',
    // searchQuery: null,
    // searchStatus: null,
    // searchPriority: null,
    // searchDisplay: true,
    // searchDisplayself: false,
    // searchTimeWarning: false,
    // timeRange: null,
    // searchTimeDeadline: null,
    // searchDepartment: null,
    // searchExecutionType: null,
    // searchType: null,
    // searchAdminBrand: null,
    // deadline_at: null,
    customize: [],
  }

  const getTodoConfigList = () => {
    isTableBusy.value = true
    getConfigData({ col: 'todo_list_config' })
      .then(response => {
        const { data } = response.data
        tableData.value = Array.isArray(data.todo_list_config.customize) ? data.todo_list_config.customize : []
        isTableBusy.value = false
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  const configData = ref(null)

  const blankSearchData = {
    name: null,
    image: null,
    sortBy: 'updated_at',
    searchQuery: null,
    searchStatus: null,
    searchPriority: null,
    searchDisplay: true,
    searchDisplayself: false,
    searchTimeWarning: false,
    timeRange: null,
    searchTimeDeadline: null,
    searchDepartment: null,
    searchExecutionType: null,
    searchType: null,
    searchAdminBrand: null,
    deadline_at: null,
  }

  const refetchTable = () => {
    getTodoConfigList()
  }

  return {
    tableData,
    isTableBusy,
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    refetchTable,
    searchOptions,

    setConfigUpdate,
    getTodoConfigList,
    useHttpCodeAlert,

    configData,
    blankSearchData,
    initSearchData,
  }
}

export const useTodoList = () => {
  const {
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    timeRange,
  } = useTableComponent()

  const {
    useHttpCodeAlert,
  } = useAlert()

  const {
    syncObject,
    syncObjectDeepAsync,
  } = useCommenSettings()

  const {
    blankContentData,
    blankConfigData,
    blankContentSetting,
  } = useQuickList()

  const {
    blankSimpleInfo,
    blankDescriptionInfo,
    blankCustomerData,

    departmentsOptions,
    adminBrandOptions,
    executionTypeOptions,
    typeOptions,
    typeGroupOptions,
    executionTypeGroupOptions,
    personalQuickOptions,
    getMetaListData,
    // getConfigData,
  } = useTodoSetting()

  const {
    blankSearchData,
    initSearchData,
  } = useSearchList()

  tableColumns.value = [
    {
      label: '#編號', key: 'id', sortable: true, searchable: false, select: true,
    },
    {
      label: '部門', key: 'department', sortable: true, searchable: true, select: true,
    },
    {
      label: '名稱', key: 'name', sortable: true, searchable: true, select: true,
    },
    {
      label: '說明', key: 'description', sortable: true, searchable: true, select: true,
    },
    {
      label: '上次更新', key: 'updated_at', sortable: true, searchable: true, select: true,
    },
    {
      label: '動作', key: 'actions', searchable: true, select: true,
    },
  ]

  const isBusy = ref(false)
  const isBusyLoading = ref(false)
  const selectedList = ref([])
  const todoListData = ref([])
  const priorityLists = ref({})
  const priorityOptions = ref([])
  const statusLists = ref({})
  const statusOptions = ref([])
  const groupLists = ref({})
  const groupOptions = ref([])
  const searchPriority = ref(null)
  const searchStatus = ref(null)
  const searchDisplay = ref(false)
  const searchDisplayself = ref(false)
  const searchDepartment = ref(null)
  const searchExecutionType = ref(null)
  const searchType = ref(null)
  const searchAdminBrand = ref(null)
  const searchTimeWarning = ref(false)
  const searchTimeDeadline = ref(null)
  const timeWarningOptions = [{ label: '無', value: false }, { label: '時間少於6H', value: true }]
  const customSearch = ref(null)

  const blankTodoData = {
    id: null,
    title: '未命名標題',
    description: '',
    admin_brand_id: null,
    department_id: null,
    created_user_id: null,
    type_id: null,
    execution_type_id: null,
    review_user_id: null,
    review_at: null,
    has_view: false,
    display_status: false,

    priority: 0,
    status: 'not-completed',
    client_name: null,
    customer_id: null,
    completed_at: null,
    deadline_at: null,
    scheduled_at: null,
    created_at: null,
    updated_at: null,
  }

  const blankViewInfo = {
    total: 0,
  }

  const blankPriorityInfo = {
    title: null,
    sign: null, // critical
    description: null,
    value: 50,
    color: '#FFFFFF',
    font_color: '#333333',
  }

  const dataMeta = computed(() => {
    const localItemsCount = totalNum.value
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * currentPage.value > totalNum.value ? totalNum.value : perPage.value * (currentPage.value - 1) + perPage.value,
      of: totalNum.value,
    }
  })

  const setTodoCreate = (...arg) => store.dispatch('admin-todo/setTodoCreate', ...arg)
  const setTodoCreateQuick = (...arg) => store.dispatch('admin-todo/setTodoCreateQuick', ...arg)
  const setTodoUpdate = (...arg) => store.dispatch('admin-todo/setTodoUpdate', ...arg)
  const setTodoDelete = (...arg) => store.dispatch('admin-todo/setTodoDelete', ...arg)
  const setTodoViewUpdate = (...arg) => store.dispatch('admin-todo/setTodoViewUpdate', ...arg)
  const setTodoCache = (...arg) => store.dispatch('admin-todo/setTodoCache', ...arg)
  const setTodoComplete = (...arg) => store.dispatch('admin-todo/setTodoComplete', ...arg)
  const setTodoReview = (...arg) => store.dispatch('admin-todo/setTodoReview', ...arg)

  perPage.value = 15
  sortBy.value = 'updated_at'

  // (初始化)搜尋Meta
  const initFilterOptions = () => {
    getMetaListData()
      .then(response => {
        //     // 陣列更新中
        const { departments } = response.data.data
        departmentsOptions.value = departments

        const adminBrands = response.data.data.admin_brands
        adminBrandOptions.value = adminBrands

        const todoTypes = response.data.data.todo_types
        typeGroupOptions.value = todoTypes

        const executionTypes = response.data.data.execution_types
        executionTypeGroupOptions.value = executionTypes

        //     // 判斷是否有在陣列中
        //     const findDepartment = departments.find(el => el.id === this.todoData.department_id)

        //     if (findDepartment === undefined) {
        //       this.todoData.department_id = null
        //     } else {
        //       this.filterTodoTypeOptions(this.todoData.department_id)
        //       this.filterTodoExecutionTypeOptions(this.todoData.department_id)
        //     }

        //     const findAdminBrand = adminBrands.find(el => el.id === this.todoData.admin_brand_id)

      //     if (findAdminBrand === undefined) {
      //       this.todoData.admin_brand_id = null
      //     }
      })
  }

  // (初始化)搜尋
  const initSearchOptions = data => {
    // getConfigData({ col: 'todo_list_config' })
    //   .then(response => {
    //     const { data } = response.data
    //     const resolveCustomSearch = syncObject(initSearchData, data.todo_list_config ? data.todo_list_config : {})
    //     if (!Array.isArray(resolveCustomSearch.customize)) return
    //     resolveCustomSearch.customize = resolveCustomSearch.customize.map(item => {
    //       const resolve = syncObject(blankSearchData, item)
    //       return resolve
    //     })
    //     customSearch.value = resolveCustomSearch
    //   })
    const todoListConfig = data
    const resolveCustomSearch = syncObject(initSearchData, data ? todoListConfig : {})
    localStorage.setItem('todoFullScreen', JSON.stringify(resolveCustomSearch.useFullScreen))
    store.commit('app/UPDATE_TODO_FULLSCREEN', resolveCustomSearch.useFullScreen)
    store.commit('admin-todo/UPDATE_USE_AUDIO_STATE', resolveCustomSearch.useAudio)
    store.commit('admin-todo/UPDATE_USE_QUICK_STATE', resolveCustomSearch.useQuick)

    if (!Array.isArray(resolveCustomSearch.customize)) return
    resolveCustomSearch.customize = resolveCustomSearch.customize.map(item => {
      const resolve = syncObject(blankSearchData, item !== null ? item : {})
      return resolve
    })
    customSearch.value = resolveCustomSearch
  }

  // (初始化)待辦列表
  const getTodoListInitData = () => {
    initFilterOptions()
    isBusy.value = true
    currentPage.value = 1
    selectedList.value = []
    // todoListData.value = []
    // ctx, callback
    // if (store.state.app.ability.user === 'none') {
    //   router.replace({ name: 'admin-home' })
    //   return
    // }

    // 整理filters
    let resolveFilters = ''

    if (searchPriority.value !== null) {
      resolveFilters = `priority: ${searchPriority.value}`
    }

    if (searchStatus.value !== null && searchStatus.value.length > 0) {
      if (resolveFilters) {
        resolveFilters = `${resolveFilters};status: ${searchStatus.value}`
      } else resolveFilters = `status: ${searchStatus.value}`
    }

    if (searchDepartment.value) {
      if (resolveFilters) {
        resolveFilters = `${resolveFilters};department_id: ${searchDepartment.value}`
      } else resolveFilters = `department_id: ${searchDepartment.value}`
    }

    if (searchExecutionType.value) {
      if (resolveFilters) {
        resolveFilters = `${resolveFilters};execution_type_id: ${searchExecutionType.value}`
      } else resolveFilters = `execution_type_id: ${searchExecutionType.value}`
    }

    if (searchType.value) {
      if (resolveFilters) {
        resolveFilters = `${resolveFilters};type_id: ${searchType.value}`
      } else resolveFilters = `type_id: ${searchType.value}`
    }

    if (searchAdminBrand.value) {
      if (resolveFilters) {
        resolveFilters = `${resolveFilters};admin_brand_id: ${searchAdminBrand.value}`
      } else resolveFilters = `admin_brand_id: ${searchAdminBrand.value}`
    }

    if (searchTimeDeadline.value) {
      if (resolveFilters) {
        resolveFilters = `${resolveFilters};deadline_at: ${searchTimeDeadline.value}`
      } else resolveFilters = `deadline_at: ${searchTimeDeadline.value}`
    }

    // 整理timeRange
    let resolveTimeRange = null
    if (timeRange.value) {
      resolveTimeRange = timeRange.value.split(' 至 ')
      const [start, end] = resolveTimeRange
      if (end) {
        resolveTimeRange = `${start} to ${end}`
      } else resolveTimeRange = `${start}`
    }

    const filterUrl = {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sort: sortBy.value,
      order: isSortDirDesc.value ? 'desc' : 'asc',
      offset: dataMeta.value.from,
      // time_warning: searchTimeWarning.value,
      // display_self: searchDisplayself.value,
      // allItem: searchDisplay.value,
      range: timeRange.value,
      filters: resolveFilters,
      _: Date.now(),
    }

    if (searchTimeWarning.value) filterUrl.time_warning = searchTimeWarning.value
    if (searchDisplayself.value) filterUrl.display_self = searchDisplayself.value
    if (searchDisplay.value) filterUrl.allItem = searchDisplay.value

    store.dispatch('admin-todo/getTodoList', filterUrl)
      .then(response => {
        const {
          data, total, priority, status, groups, configs,
        } = response.data.data

        initSearchOptions(response.data.data.todo_list_config)

        const statusKeys = Object.keys(status)
        statusOptions.value = statusKeys.map(key => {
          const option = {
            title: status[key].title,
            value: key,
            description: status[key].description,
            color: status[key].color,
            font_color: status[key].font_color,
          }
          statusLists.value[key] = option
          return option
        })

        const priorityKeys = Object.keys(priority)
        priorityOptions.value = priorityKeys.map(key => {
          const option = {
            title: priority[key].title,
            value: parseInt(key, 10),
            description: priority[key].description,
            sign: priority[key].sign,
            color: priority[key].color,
            font_color: priority[key].font_color,
          }
          priorityLists.value[key] = option
          return option
        })

        priorityOptions.value.sort((a, b) => b.value - a.value)

        groupOptions.value = groups.map(item => {
          const option = {
            label: item.name,
            value: item.id,
          }
          return option
        })

        personalQuickOptions.value = configs.map(item => {
          const resolveConfigsData = syncObject(blankConfigData, item)
          resolveConfigsData.content = syncObject({ data: {}, setting: {} }, resolveConfigsData.content)
          resolveConfigsData.content.data = syncObject(blankContentData, resolveConfigsData.content.data)
          resolveConfigsData.content.setting = syncObject(blankContentSetting, resolveConfigsData.content.setting)
          return resolveConfigsData
        })

        store.commit('admin-todo/UPDATE_PRIORITY_LIST', priorityLists.value)
        store.commit('admin-todo/UPDATE_PRIORITY_OPTIONS', priorityOptions.value)
        store.commit('admin-todo/UPDATE_STATUS_LIST', statusLists.value)
        store.commit('admin-todo/UPDATE_STATUS_OPTIONS', statusOptions.value)
        store.commit('admin-todo/UPDATE_GROUP_OPTIONS', groupOptions.value)
        store.commit('admin-todo/UPDATE_QUICK_LIST_OPTIONS', personalQuickOptions.value)

        const processItems = async items => {
          const resolveData = await Promise.all(items.map(async item => {
            const resolve = {
              ...syncObject(blankTodoData, item),
              admin_brand_info: syncObject(blankSimpleInfo, item.admin_brand_info ? item.admin_brand_info : {}),
              department_info: syncObject(blankSimpleInfo, item.department_info ? item.department_info : {}),
              created_user_info: syncObject(blankSimpleInfo, item.created_user_info ? item.created_user_info : {}),
              execution_type_info: syncObject(blankDescriptionInfo, item.execution_type_info ? item.execution_type_info : {}),
              type_info: syncObject(blankDescriptionInfo, item.type_info ? item.type_info : {}),
              priority_info: syncObject(blankPriorityInfo, item.priority_info ? item.priority_info : {}),
              status_info: statusLists.value[item.status],
              review_user_info: syncObject(blankSimpleInfo, item.review_user_info ? item.review_user_info : {}),
              view_info: syncObject(blankViewInfo, item.view_info ? item.view_info : {}),
              customer: await syncObjectDeepAsync(blankCustomerData, item.customer_id ? item.customer : {}), // && item.customer.id
            }
            return resolve
          }))
          return resolveData
        }

        processItems(data)
          .then(resolveData => {
            todoListData.value = resolveData

            if (resolveData.length > 0) {
              const [first] = resolveData
              const firstList = {
                id: first.id,
                total: first.view_info.total,
              }
              store.commit('admin-todo/UPDATE_FIRST_LIST_INFO', firstList)
              isBusy.value = false
            }
          })

        totalNum.value = total
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  // (獲取)下一頁資料
  const getTodoListNextData = () => {
    if (todoListData.value.length > 0) isBusyLoading.value = true

    // 整理filters
    let resolveFilters = ''

    if (searchPriority.value !== null) {
      resolveFilters = `priority: ${searchPriority.value}`
    }

    if (searchStatus.value !== null && searchStatus.value.length > 0) {
      if (resolveFilters) {
        resolveFilters = `${resolveFilters};status: ${searchStatus.value}`
      } else resolveFilters = `status: ${searchStatus.value}`
    }

    if (searchDepartment.value) {
      if (resolveFilters) {
        resolveFilters = `${resolveFilters};department_id: ${searchDepartment.value}`
      } else resolveFilters = `department_id: ${searchDepartment.value}`
    }

    if (searchExecutionType.value) {
      if (resolveFilters) {
        resolveFilters = `${resolveFilters};execution_type_id: ${searchExecutionType.value}`
      } else resolveFilters = `execution_type_id: ${searchExecutionType.value}`
    }

    if (searchType.value) {
      if (resolveFilters) {
        resolveFilters = `${resolveFilters};type_id: ${searchType.value}`
      } else resolveFilters = `type_id: ${searchType.value}`
    }

    if (searchAdminBrand.value) {
      if (resolveFilters) {
        resolveFilters = `${resolveFilters};admin_brand_id: ${searchAdminBrand.value}`
      } else resolveFilters = `admin_brand_id: ${searchAdminBrand.value}`
    }

    if (searchTimeDeadline.value) {
      if (resolveFilters) {
        resolveFilters = `${resolveFilters};deadline_at: ${searchTimeDeadline.value}`
      } else resolveFilters = `deadline_at: ${searchTimeDeadline.value}`
    }

    // 整理timeRange
    let resolveTimeRange = null
    if (timeRange.value) {
      resolveTimeRange = timeRange.value.split(' 至 ')
      const [start, end] = resolveTimeRange
      if (end) {
        resolveTimeRange = `${start} to ${end}`
      } else resolveTimeRange = `${start}`
    }

    const filterUrl = {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sort: sortBy.value,
      order: isSortDirDesc.value ? 'desc' : 'asc',
      offset: dataMeta.value.from,
      range: timeRange.value,
      filters: resolveFilters,
    }

    if (searchTimeWarning.value) filterUrl.time_warning = searchTimeWarning.value
    if (searchDisplayself.value) filterUrl.display_self = searchDisplayself.value
    if (searchDisplay.value) filterUrl.allItem = searchDisplay.value

    store.dispatch('admin-todo/getTodoList', filterUrl)
      .then(response => {
        isBusy.value = false
        isBusyLoading.value = false
        const { data, total } = response.data.data

        const processItems = async items => {
          const resolveData = await Promise.all(items.map(async item => {
            const resolve = {
              ...syncObject(blankTodoData, item),
              admin_brand_info: syncObject(blankSimpleInfo, item.admin_brand_info ? item.admin_brand_info : {}),
              department_info: syncObject(blankSimpleInfo, item.department_info ? item.department_info : {}),
              created_user_info: syncObject(blankSimpleInfo, item.created_user_info ? item.created_user_info : {}),
              execution_type_info: syncObject(blankDescriptionInfo, item.execution_type_info ? item.execution_type_info : {}),
              type_info: syncObject(blankDescriptionInfo, item.type_info ? item.type_info : {}),
              priority_info: syncObject(blankPriorityInfo, item.priority_info ? item.priority_info : {}),
              status_info: statusLists.value[item.status],
              review_user_info: syncObject(blankSimpleInfo, item.review_user_info ? item.review_user_info : {}),
              view_info: syncObject(blankViewInfo, item.view_info ? item.view_info : {}),
              customer: await syncObjectDeepAsync(blankCustomerData, item.customer_id ? item.customer : {}),
            }
            return resolve
          }))
          return resolveData
        }

        processItems(data)
          .then(resolveData => {
            resolveData.forEach(el => {
              todoListData.value.push(el)
            })
          })

        totalNum.value = total
      })
  }

  // (獲取)當前數量
  const refreshTodoListPageData = quick => {
    if (!quick) {
      initFilterOptions()
    }
    if (isBusy.value) return
    isBusy.value = true
    // selectedList.value = []
    // 整理filters
    let resolveFilters = ''

    if (searchPriority.value !== null) {
      resolveFilters = `priority: ${searchPriority.value}`
    }

    if (searchStatus.value !== null && searchStatus.value.length > 0) {
      if (resolveFilters) {
        resolveFilters = `${resolveFilters};status: ${searchStatus.value}`
      } else resolveFilters = `status: ${searchStatus.value}`
    }

    if (searchDepartment.value) {
      if (resolveFilters) {
        resolveFilters = `${resolveFilters};department_id: ${searchDepartment.value}`
      } else resolveFilters = `department_id: ${searchDepartment.value}`
    }

    if (searchExecutionType.value) {
      if (resolveFilters) {
        resolveFilters = `${resolveFilters};execution_type_id: ${searchExecutionType.value}`
      } else resolveFilters = `execution_type_id: ${searchExecutionType.value}`
    }

    if (searchType.value) {
      if (resolveFilters) {
        resolveFilters = `${resolveFilters};type_id: ${searchType.value}`
      } else resolveFilters = `type_id: ${searchType.value}`
    }

    if (searchAdminBrand.value) {
      if (resolveFilters) {
        resolveFilters = `${resolveFilters};admin_brand_id: ${searchAdminBrand.value}`
      } else resolveFilters = `admin_brand_id: ${searchAdminBrand.value}`
    }

    if (searchTimeDeadline.value) {
      if (resolveFilters) {
        resolveFilters = `${resolveFilters};deadline_at: ${searchTimeDeadline.value}`
      } else resolveFilters = `deadline_at: ${searchTimeDeadline.value}`
    }

    // 整理timeRange
    let resolveTimeRange = null
    if (timeRange.value) {
      resolveTimeRange = timeRange.value.split(' 至 ')
      const [start, end] = resolveTimeRange
      if (end) {
        resolveTimeRange = `${start} to ${end}`
      } else resolveTimeRange = `${start}`
    }

    const filterUrl = {
      search: searchQuery.value,
      limit: perPage.value, // todoListData.value.length === totalNum.value ? totalNum.value + 1 : perPage.value * currentPage.value
      page: 1,
      sort: sortBy.value,
      order: isSortDirDesc.value ? 'desc' : 'asc',
      offset: dataMeta.value.from,
      range: timeRange.value,
      filters: resolveFilters,
    }

    if (searchTimeWarning.value) filterUrl.time_warning = searchTimeWarning.value
    if (searchDisplayself.value) filterUrl.display_self = searchDisplayself.value
    if (searchDisplay.value) filterUrl.allItem = searchDisplay.value

    store.dispatch('admin-todo/getTodoList', filterUrl)
      .then(response => {
        const { data, total, configs } = response.data.data

        initSearchOptions(response.data.data.todo_list_config)

        personalQuickOptions.value = configs.map(item => {
          const resolveConfigsData = syncObject(blankConfigData, item)
          resolveConfigsData.content = syncObject({ data: {}, setting: {} }, resolveConfigsData.content)
          resolveConfigsData.content.data = syncObject(blankContentData, resolveConfigsData.content.data)
          resolveConfigsData.content.setting = syncObject(blankContentSetting, resolveConfigsData.content.setting)
          return resolveConfigsData
        })

        store.commit('admin-todo/UPDATE_QUICK_LIST_OPTIONS', personalQuickOptions.value)

        const processItems = async items => {
          const resolveData = await Promise.all(items.map(async item => {
            const resolve = {
              ...syncObject(blankTodoData, item),
              admin_brand_info: syncObject(blankSimpleInfo, item.admin_brand_info ? item.admin_brand_info : {}),
              department_info: syncObject(blankSimpleInfo, item.department_info ? item.department_info : {}),
              created_user_info: syncObject(blankSimpleInfo, item.created_user_info ? item.created_user_info : {}),
              execution_type_info: syncObject(blankDescriptionInfo, item.execution_type_info ? item.execution_type_info : {}),
              type_info: syncObject(blankDescriptionInfo, item.type_info ? item.type_info : {}),
              priority_info: syncObject(blankPriorityInfo, item.priority_info ? item.priority_info : {}),
              status_info: statusLists.value[item.status],
              review_user_info: syncObject(blankSimpleInfo, item.review_user_info ? item.review_user_info : {}),
              view_info: syncObject(blankViewInfo, item.view_info ? item.view_info : {}),
              customer: await syncObjectDeepAsync(blankCustomerData, item.customer_id ? item.customer : {}),
            }
            return resolve
          }))
          return resolveData
        }

        processItems(data)
          .then(resolveData => {
            todoListData.value = resolveData
            if (resolveData.length > 0) {
              const [first] = resolveData
              const firstList = {
                id: first.id,
                total: first.view_info.total,
              }
              store.commit('admin-todo/UPDATE_FIRST_LIST_INFO', firstList)
            }
            isBusy.value = false
          })
        totalNum.value = total
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  const refetchData = () => getTodoListInitData()

  watch([searchQuery, searchStatus, searchPriority, searchDisplay, searchTimeWarning, timeRange,
    searchDepartment, searchExecutionType, searchType, searchAdminBrand, isSortDirDesc, sortBy,
    searchTimeDeadline, searchDisplayself,
  ], () => {
    currentPage.value = 1
    selectedList.value = []
    refreshTodoListPageData(true)
  })

  watch([currentPage], () => {
    if (currentPage.value === 1) return
    getTodoListNextData()
  })

  return {
    isBusy,
    isBusyLoading,
    todoListData,
    priorityLists,
    priorityOptions,
    statusLists,
    statusOptions,
    groupLists,
    groupOptions,
    searchPriority,
    searchStatus,
    searchDisplay,
    searchDisplayself,
    searchTimeWarning,
    searchDepartment,
    searchExecutionType,
    searchType,
    searchAdminBrand,
    searchTimeDeadline,
    departmentsOptions,
    adminBrandOptions,
    executionTypeOptions,
    typeOptions,
    typeGroupOptions,
    executionTypeGroupOptions,
    timeWarningOptions,
    customSearch,
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    timeRange,
    selectedList,
    refetchData,
    // refetchTable,

    setTodoCreate,
    setTodoCreateQuick,
    setTodoUpdate,
    setTodoDelete,
    setTodoViewUpdate,
    setTodoCache,
    setTodoComplete,
    setTodoReview,
    getTodoListInitData,
    getTodoListNextData,
    refreshTodoListPageData,
    useHttpCodeAlert,
    syncObject,
  }
}

export const useTodoView = () => {
  const showTodoDetails = ref(false)
  const todoData = ref(null)

  const isViewBusy = ref(false)
  // const roleLists = ref({})
  // const roleOptions = ref([])

  const {
    syncObject,
    syncObjectDeepAsync,
  } = useCommenSettings()

  const {
    priorityLists,
    priorityOptions,
    statusLists,
    statusOptions,
    groupLists,
    groupOptions,
  } = useTodoList()

  const {
    roleLists,
    roleOptions,
    blankSimpleInfo,
    blankDescriptionInfo,
    blankCustomerData,
  } = useTodoSetting()

  const blankTodoData = {
    id: null,
    title: null,
    serialNumber: null,
    description: '<p><br></p>',
    admin_brand_id: null,
    department_id: null,
    group_id: null,
    type_id: null,
    execution_type_id: null,
    review_user_id: null,
    created_user_id: null,
    completed_user_id: null,
    review_at: null,
    appends: [],
    assigned_user: [],
    remarks: [], // *delete
    remark_message: '',
    display_status: true,
    has_view: false,

    priority: 0,
    status: 'not-completed',
    client_name: null,
    customer_id: null,
    completed_at: null,
    deadline_at: null,
    scheduled_at: null,
    created_at: null,
    updated_at: null,
  }

  // const blankAppendsData = {
  //   id: null,
  //   extension: null,
  //   name: null,
  // }

  const blankAssignedData = {
    id: null,
    role: 'executor',
    description: '',
    user_id: null,
  }

  const showUserNumber = 3

  const getTodoInfoData = (...arg) => store.dispatch('admin-todo/getTodoData', ...arg)
  const setTodoAppendUpload = (...arg) => store.dispatch('admin-todo/setTodoAppendUpload', ...arg)
  const setTodoAppendUpdate = (...arg) => store.dispatch('admin-todo/setTodoAppendUpdate', ...arg)
  const setTodoAppendDelete = (...arg) => store.dispatch('admin-todo/setTodoAppendDelete', ...arg)

  const resolveTodoInfoData = async response => {
    const {
      data, priority, status, groups,
    } = response.data.data

    const role = response.data.data.todo_role

    const statusKeys = Object.keys(status)
    statusOptions.value = statusKeys.map(key => {
      const option = {
        title: status[key].title,
        value: key,
        description: status[key].description,
        color: status[key].color,
        font_color: status[key].font_color,
      }
      statusLists.value[key] = option
      return option
    })

    const priorityKeys = Object.keys(priority)
    priorityOptions.value = priorityKeys.map(key => {
      const option = {
        title: priority[key].title,
        value: key,
        description: priority[key].description,
        sign: priority[key].sign,
        color: priority[key].color,
        font_color: priority[key].font_color,
      }
      priorityLists.value[key] = option
      return option
    })

    priorityOptions.value.sort((a, b) => b.value - a.value)

    const roleKeys = Object.keys(role)
    roleOptions.value = roleKeys.map(key => {
      const option = {
        title: role[key].title,
        value: key,
        description: role[key].description,
      }
      roleLists.value[key] = option
      return option
    })

    groupOptions.value = groups.map(item => {
      const option = {
        label: item.name,
        value: item.id,
      }
      groupLists.value[item.id] = item.name
      return option
    })

    const resolvedCustomerData = await syncObjectDeepAsync(blankCustomerData, data.customer_id && data.customer ? data.customer : {})

    const resolveData = {
      data: {
        ...syncObject(blankTodoData, data),
        department_info: data.department_info ? syncObject(blankSimpleInfo, data.department_info) : syncObject(blankSimpleInfo, {}),
        admin_brand_info: data.admin_brand_id && data.admin_brand_info ? syncObject(blankSimpleInfo, data.admin_brand_info) : syncObject(blankSimpleInfo, {}),
        execution_type_info: data.execution_type_info ? syncObject(blankDescriptionInfo, data.execution_type_info) : syncObject(blankDescriptionInfo, {}),
        type_info: data.type_info ? syncObject(blankDescriptionInfo, data.type_info) : syncObject(blankDescriptionInfo, {}),
        review_user_info: data.review_user_info ? syncObject(blankSimpleInfo, data.review_user_info) : syncObject(blankSimpleInfo, {}),
        created_user_info: data.created_user_info ? syncObject(blankSimpleInfo, data.created_user_info) : syncObject(blankSimpleInfo, {}),
        complete_user_info: data.complete_user_info ? syncObject(blankSimpleInfo, data.complete_user_info) : syncObject(blankSimpleInfo, {}),
        customer: resolvedCustomerData, // data.customer_id && data.customer ? syncObject(blankCustomerData, data.customer) : syncObject(blankCustomerData, {}),
      },
      priorityOptions: priorityOptions.value,
      priorityLists: priorityLists.value,
      statusOptions: statusOptions.value,
      statusLists: statusLists.value,
      roleOptions: roleOptions.value,
      roleLists: roleLists.value,
      groupOptions: groupOptions.value,
      groupLists: groupLists.value,
    }

    resolveData.data.assigned_user = data.assigned_user.map(item => {
      const resolve = {
        ...syncObject(blankAssignedData, item),
        user_info: syncObject(blankSimpleInfo, item.user_info),
        department_info: { ...blankSimpleInfo },
      }

      if (item.user_info.department_id && item.user_info.department_info) {
        resolve.department_info = syncObject(blankSimpleInfo, item.user_info.department_info)
      }
      return resolve
    })

    resolveData.data.show_user = {
      array: [],
      number: 0,
    }

    const userLength = resolveData.data.assigned_user.length

    if (userLength > showUserNumber) {
      resolveData.data.show_user.number = userLength - showUserNumber
      resolveData.data.show_user.array = resolveData.data.assigned_user.slice(0, showUserNumber)
    } else resolveData.data.show_user.array = resolveData.data.assigned_user.slice(0, userLength)

    return resolveData
  }
  //  async

  const resolveTodoViewData = response => {
    const {
      data, priority, status,
    } = response.data.data

    const role = response.data.data.todo_role

    const statusKeys = Object.keys(status)
    statusOptions.value = statusKeys.map(key => {
      const option = {
        title: status[key].title,
        value: key,
        description: status[key].description,
        color: status[key].color,
        font_color: status[key].font_color,
      }
      statusLists.value[key] = option
      return option
    })

    const priorityKeys = Object.keys(priority)
    priorityOptions.value = priorityKeys.map(key => {
      const option = {
        title: priority[key].title,
        value: key,
        description: priority[key].description,
        sign: priority[key].sign,
        color: priority[key].color,
        font_color: priority[key].font_color,
      }
      priorityLists.value[key] = option
      return option
    })

    const roleKeys = Object.keys(role)
    roleOptions.value = roleKeys.map(key => {
      const option = {
        title: role[key].title,
        value: key,
        description: role[key].description,
      }
      roleLists.value[key] = option
      return option
    })

    const resolveData = {
      data: {
        ...syncObject(blankTodoData, data),
        department_info: data.department_info ? syncObject(blankSimpleInfo, data.department_info) : syncObject(blankSimpleInfo, {}),
        admin_brand_info: data.admin_brand_id && data.admin_brand_info ? syncObject(blankSimpleInfo, data.admin_brand_info) : syncObject(blankSimpleInfo, {}),
        execution_type_info: data.execution_type_info ? syncObject(blankDescriptionInfo, data.execution_type_info) : syncObject(blankDescriptionInfo, {}),
        type_info: data.type_info ? syncObject(blankDescriptionInfo, data.type_info) : syncObject(blankDescriptionInfo, {}),
        review_user_info: data.review_user_info ? syncObject(blankSimpleInfo, data.review_user_info) : syncObject(blankSimpleInfo, {}),
      },
      remarks: data.remarks ? data.remarks : [],
      priorityOptions: priorityOptions.value,
      priorityLists: priorityLists.value,
      statusOptions: statusOptions.value,
      statusLists: statusLists.value,
      roleOptions: roleOptions.value,
      roleLists: roleLists.value,
    }

    // resolveData.data.appends = data.appends.map(item => {
    //   const resolve = {
    //     ...syncObject(blankAppendsData, item),
    //   }
    //   return resolve
    // })

    resolveData.data.assigned_user = data.assigned_user.map(item => {
      const resolve = {
        ...syncObject(blankAssignedData, item),
        user_info: syncObject(blankSimpleInfo, item.user_info),
        department_info: { ...blankSimpleInfo },
      }

      if (item.user_info.department_id && item.user_info.department_info) {
        resolve.department_info = syncObject(blankSimpleInfo, item.user_info.department_info)
      }
      return resolve
    })

    resolveData.data.show_user = {
      array: [],
      number: 0,
    }

    const userLength = resolveData.data.assigned_user.length

    if (userLength > showUserNumber) {
      resolveData.data.show_user.number = userLength - showUserNumber
      resolveData.data.show_user.array = resolveData.data.assigned_user.slice(0, showUserNumber)
    } else resolveData.data.show_user.array = resolveData.data.assigned_user.slice(0, userLength)

    return resolveData
  }

  return {
    showTodoDetails,
    todoData,
    isViewBusy,
    blankAssignedData,
    getTodoInfoData,
    resolveTodoInfoData,
    resolveTodoViewData,

    setTodoAppendUpload,
    setTodoAppendUpdate,
    setTodoAppendDelete,
  }
}

export const useRemarkList = () => {
  const isRemarkBusy = ref(false)
  const remarkLists = ref([])
  const remarkText = ref(null)
  const randomIdArray = ref([])
  const randomKey = ref(null)

  // const {
  //   blankSimpleInfo,
  //   // blankDescriptionInfo,
  // } = useTodoSetting()

  const {
    syncObject,
  } = useCommenSettings()

  const blankRemarkData = {
    rid: null,
    id: null,
    body: {},
    ip: null,
    message: null,
    is_system: false,
    user_id: null,
    appends: [],
    created_at: null,
    updated_at: null,
  }

  const blankUserData = {
    name: null,
    image: null,
  }

  const blankBodyData = {
    images: [],
    reply_id: null,
  }

  const blankImageData = {
    append_id: null,
    preview_image: null,
  }

  const blankAppendData = {
    rid: null,
    extension: null,
    id: null,
    name: null,
    remark_id: null,
    size: null,
    file: null,
  }

  const judgeTypeOption = [
    'image/png',
    'image/jpeg',
  ]

  const setTodoRemarkCreate = (...arg) => store.dispatch('admin-todo/setTodoRemarkCreate', ...arg)
  const setTodoRemarkUpdate = (...arg) => store.dispatch('admin-todo/setTodoRemarkUpdate', ...arg)
  const setTodoRemarkDelete = (...arg) => store.dispatch('admin-todo/setTodoRemarkDelete', ...arg)

  const getRemarkAppendData = (...arg) => store.dispatch('admin-todo/getRemarkAppendData', ...arg)
  const setRemarkAppendUpload = data => store.dispatch('admin-todo/setRemarkAppendUpload', data)
  const getRemarkAppendDownloadKey = (...arg) => store.dispatch('admin-todo/getRemarkAppendDownloadKey', ...arg)
  // const getRemarkAppendDownload = (...arg) => store.dispatch('admin-todo/getRemarkAppendDownload', ...arg)

  const getTodoRemarkList = (...arg) => store.dispatch('admin-todo/getTodoRemarkList', ...arg)
  const getTodoRemarkSearch = (...arg) => store.dispatch('admin-todo/getTodoRemarkSearch', ...arg)

  // 產生亂數雜錯
  const generateRandomString = () => {
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
    let randomString = ''

    do {
      for (let i = 0; i < 20; i += 1) {
        const randomIndex = Math.floor(Math.random() * characters.length)
        randomString += characters[randomIndex]
      }
    } while (randomIdArray.value.includes(randomString))

    randomIdArray.value.push(randomString)
    return randomString
  }

  const removeRandomIdArray = rId => {
    randomIdArray.value = randomIdArray.value.filter(item => item !== rId)
  }

  const resolveRemarkInfoData = remark => {
    const resolveData = {
      // rid: generateRandomString(),
      ...syncObject(blankRemarkData, remark),
    }
    resolveData.body = syncObject(blankBodyData, resolveData.body)
    let images = []
    if (resolveData.body && resolveData.body.images) {
      images = resolveData.body.images.map(item => {
        const resolveImage = syncObject(blankImageData, item)
        return resolveImage
      })
    }
    resolveData.body.images = images

    let appends = []
    if (remark.appends) {
      appends = remark.appends.map(item => {
        const resolveAppends = syncObject(blankAppendData, item)
        return resolveAppends
      })
    }
    resolveData.appends = appends
    resolveData.user_info = syncObject(blankUserData, remark.user_info ? remark.user_info : {})
    return resolveData
  }

  const resolveRemarkListData = response => {
    const { remarks } = response.data.data
    const resolveData = remarks.reverse().map(item => resolveRemarkInfoData(item))
    return resolveData
  }

  const isSameDay = (N1, N2) => {
    const date1 = new Date(N1)
    const date2 = new Date(N2)
    const state = date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate()
    return state
  }

  return {
    isRemarkBusy,
    remarkText,
    remarkLists,
    randomKey,
    randomIdArray,
    blankAppendData,
    blankImageData,
    judgeTypeOption,

    resolveRemarkListData,
    resolveRemarkInfoData,
    generateRandomString,
    removeRandomIdArray,
    isSameDay,
    // isViewBusy,

    // setTodoAppendUpload,
    // setTodoAppendUpdate,
    // setTodoAppendDelete,
    getTodoRemarkList,
    getTodoRemarkSearch,
    setTodoRemarkCreate,
    setTodoRemarkUpdate,
    setTodoRemarkDelete,

    getRemarkAppendData,
    setRemarkAppendUpload,
    getRemarkAppendDownloadKey,
    // getRemarkAppendDownload,
  }
}

export const useTodoClassList = () => {
  const {
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    refetchData,
  } = useTableComponent()

  const {
    useHttpCodeAlert,
  } = useAlert()

  tableColumns.value = [
    {
      label: '#編號', key: 'id', sortable: true, searchable: false, select: true,
    },
    {
      label: '部門', key: 'department', sortable: true, searchable: true, select: true,
    },
    {
      label: '名稱', key: 'name', sortable: true, searchable: true, select: true,
    },
    {
      label: '說明', key: 'description', sortable: true, searchable: true, select: true,
    },
    {
      label: '今日', key: 'today_total', sortable: true, searchable: true, select: true,
    },
    {
      label: '本週', key: 'week_total', sortable: true, searchable: true, select: false,
    },
    {
      label: '本月', key: 'month_total', sortable: true, searchable: true, select: false,
    },
    {
      label: '昨日', key: 'yesterday_total', sortable: true, searchable: true, select: false,
    },
    {
      label: '上週', key: 'last_week_total', sortable: true, searchable: true, select: false,
    },
    {
      label: '上月', key: 'last_month_total', sortable: true, searchable: true, select: false,
    },
    {
      label: '完成總數', key: 'complete_total', sortable: true, searchable: true, select: false,
    },
    {
      label: '待辦總數', key: 'total', sortable: true, searchable: true, select: false,
    },
    {
      label: '完成率', key: 'complete', sortable: true, searchable: true, select: true,
    },
    {
      label: '上次更新', key: 'updated_at', sortable: true, searchable: true, select: true,
    },
    {
      label: '動作', key: 'actions', searchable: true, select: true,
    },
  ]

  const isBusy = ref(false)
  const timeRange = ref(null)

  // const blankEditClassData = {
  //   name: null,
  //   description: null,
  //   department_id: null,
  // }

  const blankClassData = {
    id: null,
    name: null,
    description: null,
    department_id: null,
    total: 0,
    complete_total: 0,
    today_total: 0,
    week_total: 0,
    month_total: 0,
    yesterday_total: 0,
    last_week_total: 0,
    last_month_total: 0,
    updated_at: null,
    created_at: null,
  }

  const {
    syncObject,
  } = useCommenSettings()

  const {
    blankChartData,
    blankDepartmentInfo,
    resolveChartDataColor,
  } = useTodoSetting()

  const percentageCorrect = (num, total) => {
    const percentage = num === 0 ? 0 : (num / total) * 100
    return percentage
  }

  const normalizeNumbers = (num, total) => {
    if (total) return 0
    const percentNumber = (num / total) * 100
    return percentNumber.toFixed(0)
  }

  const setTodoClassDelete = (...arg) => store.dispatch('admin-todo/setTodoTypeDelete', ...arg)
  const setTodoClassCreate = (...arg) => store.dispatch('admin-todo/setTodoTypeCreate', ...arg)
  const setTodoClassUpdate = (...arg) => store.dispatch('admin-todo/setTodoTypeUpdate', ...arg)

  const getTodoClassListData = (ctx, callback) => {
    // if (store.state.app.ability.user === 'none') {
    //   router.replace({ name: 'admin-home' })
    //   return
    // }

    // 整理timeRange
    // let resolveTimeRange = null
    // if (timeRange.value) {
    //   resolveTimeRange = timeRange.value.split(' 至 ')
    //   const [start, end] = resolveTimeRange
    //   if (end) {
    //     resolveTimeRange = `${start} to ${end}`
    //   } else resolveTimeRange = `${start}`
    // }

    store.dispatch('admin-todo/getTodoTypeList', {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sort: sortBy.value,
      order: isSortDirDesc.value ? 'desc' : 'asc',
      offset: dataMeta.value.from,
      // range: resolveTimeRange,
      // filters: resolveFilters,
      _: Date.now(),
    })
      .then(response => {
        const { data, total } = response.data.data

        const resolveData = data.map(item => {
          const resolve = {
            ...syncObject(blankClassData, item),
            department_info: item.department_info ? syncObject(blankDepartmentInfo, item.department_info) : syncObject(blankDepartmentInfo, {}),
            chartDataAll: JSON.parse(JSON.stringify(blankChartData)),
            percentageAll: item.total ? percentageCorrect(item.complete_total, item.total).toFixed(2) : 100,
          }
          resolve.chartDataAll.series[0] = item.total ? normalizeNumbers(item.complete_total, item.total) : 100
          resolve.chartDataAll.options.colors[0] = resolveChartDataColor(item.total ? normalizeNumbers(item.complete_total, item.total) : 100)
          return resolve
        })
        callback(resolveData)
        totalNum.value = total
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  const refetchTable = () => {
    if (currentPage.value !== 1) {
      currentPage.value = 1
    } else refetchData()
  }

  watch([currentPage, perPage, searchQuery, timeRange], () => {
    refetchData()
  })

  return {
    isBusy,
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    timeRange,
    refetchData,
    refetchTable,

    setTodoClassDelete,
    setTodoClassCreate,
    setTodoClassUpdate,
    getTodoClassListData,
    useHttpCodeAlert,
  }
}

export const useTodoExecutionList = () => {
  const {
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    refetchData,
  } = useTableComponent()

  const {
    useHttpCodeAlert,
  } = useAlert()

  tableColumns.value = [
    {
      label: '#編號', key: 'id', sortable: true, searchable: false, select: true,
    },
    {
      label: '部門', key: 'department', sortable: true, searchable: true, select: true,
    },
    {
      label: '名稱', key: 'name', sortable: true, searchable: true, select: true,
    },
    {
      label: '說明', key: 'description', sortable: true, searchable: true, select: true,
    },
    {
      label: '今日', key: 'today_total', sortable: true, searchable: true, select: true,
    },
    {
      label: '本週', key: 'week_total', sortable: true, searchable: true, select: false,
    },
    {
      label: '本月', key: 'month_total', sortable: true, searchable: true, select: false,
    },
    {
      label: '昨日', key: 'yesterday_total', sortable: true, searchable: true, select: false,
    },
    {
      label: '上週', key: 'last_week_total', sortable: true, searchable: true, select: false,
    },
    {
      label: '上月', key: 'last_month_total', sortable: true, searchable: true, select: false,
    },
    {
      label: '完成總數', key: 'complete_total', sortable: true, searchable: true, select: false,
    },
    {
      label: '待辦總數', key: 'total', sortable: true, searchable: true, select: false,
    },
    {
      label: '完成率', key: 'complete', sortable: true, searchable: true, select: true,
    },
    {
      label: '上次更新', key: 'updated_at', sortable: true, searchable: true, select: true,
    },
    {
      label: '動作', key: 'actions', searchable: true, select: true,
    },
  ]

  const isBusy = ref(false)
  const timeRange = ref(null)

  // const blankEditClassData = {
  //   name: null,
  //   description: null,
  //   department_id: null,
  // }

  const blankClassData = {
    id: null,
    name: null,
    description: null,
    department_id: null,
    total: 0,
    complete_total: 0,
    today_total: 0,
    week_total: 0,
    month_total: 0,
    yesterday_total: 0,
    last_week_total: 0,
    last_month_total: 0,
    updated_at: null,
    created_at: null,
  }

  const {
    syncObject,
  } = useCommenSettings()

  const {
    blankChartData,
    blankDepartmentInfo,
    resolveChartDataColor,
  } = useTodoSetting()

  const percentageCorrect = (num, total) => {
    const percentage = num === 0 ? 0 : (num / total) * 100
    return percentage
  }

  const normalizeNumbers = (num, total) => {
    if (total) return 0
    const percentNumber = (num / total) * 100
    return percentNumber.toFixed(0)
  }

  const setTodoClassDelete = (...arg) => store.dispatch('admin-todo/setTodoExecutionDelete', ...arg)
  const setTodoClassCreate = (...arg) => store.dispatch('admin-todo/setTodoExecutionCreate', ...arg)
  const setTodoClassUpdate = (...arg) => store.dispatch('admin-todo/setTodoExecutionUpdate', ...arg)

  const getTodoClassListData = (ctx, callback) => {
    // if (store.state.app.ability.user === 'none') {
    //   router.replace({ name: 'admin-home' })
    //   return
    // }

    // 整理timeRange
    // let resolveTimeRange = null
    // if (timeRange.value) {
    //   resolveTimeRange = timeRange.value.split(' 至 ')
    //   const [start, end] = resolveTimeRange
    //   if (end) {
    //     resolveTimeRange = `${start} to ${end}`
    //   } else resolveTimeRange = `${start}`
    // }

    store.dispatch('admin-todo/getTodoExecutionList', {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sort: sortBy.value,
      order: isSortDirDesc.value ? 'desc' : 'asc',
      offset: dataMeta.value.from,
      // range: resolveTimeRange,
      // filters: resolveFilters,
      _: Date.now(),
    })
      .then(response => {
        const { data, total } = response.data.data

        const resolveData = data.map(item => {
          const resolve = {
            ...syncObject(blankClassData, item),
            department_info: item.department_info ? syncObject(blankDepartmentInfo, item.department_info) : syncObject(blankDepartmentInfo, {}),
            chartDataAll: JSON.parse(JSON.stringify(blankChartData)),
            percentageAll: item.total ? percentageCorrect(item.complete_total, item.total).toFixed(2) : 100,
          }
          resolve.chartDataAll.series[0] = item.total ? normalizeNumbers(item.complete_total, item.total) : 100
          resolve.chartDataAll.options.colors[0] = resolveChartDataColor(item.total ? normalizeNumbers(item.complete_total, item.total) : 100)
          return resolve
        })
        callback(resolveData)
        totalNum.value = total
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  const refetchTable = () => {
    if (currentPage.value !== 1) {
      currentPage.value = 1
    } else refetchData()
  }

  watch([currentPage, perPage, searchQuery, timeRange], () => {
    refetchData()
  })

  return {
    isBusy,
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    timeRange,
    refetchData,
    refetchTable,

    setTodoClassDelete,
    setTodoClassCreate,
    setTodoClassUpdate,
    getTodoClassListData,
    useHttpCodeAlert,
  }
}

export const useTodoGroupList = () => {
  const {
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    refetchData,
  } = useTableComponent()

  const {
    useHttpCodeAlert,
  } = useAlert()

  tableColumns.value = [
    {
      label: '#編號', key: 'id', sortable: true, searchable: false, select: true,
    },
    {
      label: '公共', key: 'public', sortable: true, searchable: true, select: true,
    },
    {
      label: '名稱', key: 'name', sortable: true, searchable: true, select: true,
    },
    {
      label: '說明', key: 'description', sortable: true, searchable: true, select: true,
    },
    {
      label: '總數', key: 'use_total', sortable: true, searchable: true, select: true,
    },
    {
      label: '上次更新', key: 'updated_at', sortable: true, searchable: true, select: true,
    },
    {
      label: '動作', key: 'actions', searchable: true, select: true,
    },
  ]

  const isBusy = ref(false)
  const timeRange = ref(null)

  // const blankEditClassData = {
  //   name: null,
  //   description: null,
  //   department_id: null,
  // }

  const blankGroupData = {
    id: null,
    name: null,
    description: '',
    use_total: 0,
    public: false,
    updated_at: null,
    created_at: null,
  }

  const {
    syncObject,
  } = useCommenSettings()

  const setTodoGroupDelete = (...arg) => store.dispatch('admin-todo/setTodoGroupDelete', ...arg)
  const setTodoGroupCreate = (...arg) => store.dispatch('admin-todo/setTodoGroupCreate', ...arg)
  const setTodoGroupUpdate = (...arg) => store.dispatch('admin-todo/setTodoGroupUpdate', ...arg)

  const getTodoGroupListData = (ctx, callback) => {
    store.dispatch('admin-todo/getTodoGroupList', {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sort: sortBy.value,
      order: isSortDirDesc.value ? 'desc' : 'asc',
      offset: dataMeta.value.from,
      _: Date.now(),
    })
      .then(response => {
        const { data, total } = response.data.data

        const resolveData = data.map(item => {
          const resolve = {
            ...syncObject(blankGroupData, item),
          }
          return resolve
        })
        callback(resolveData)
        totalNum.value = total
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  const refetchTable = () => {
    if (currentPage.value !== 1) {
      currentPage.value = 1
    } else refetchData()
  }

  watch([currentPage, perPage, searchQuery, timeRange], () => {
    refetchData()
  })

  return {
    isBusy,
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    timeRange,
    refetchData,
    refetchTable,
    blankGroupData,

    setTodoGroupDelete,
    setTodoGroupCreate,
    setTodoGroupUpdate,
    getTodoGroupListData,
    useHttpCodeAlert,
  }
}

export const useTodoGroupView = () => {
  const {
    useHttpCodeAlert,
  } = useAlert()

  const {
    syncObject,
  } = useCommenSettings()

  const {
    isBusy,
    blankGroupData,
  } = useTodoGroupList()

  const groupInfo = ref(null)
  const userArray = ref([])

  const setTodoGroupUserData = (...arg) => store.dispatch('admin-todo/setTodoGroupUserData', ...arg)

  const getTodoGroupData = (groupId, reset) => {
    isBusy.value = true
    store.dispatch('admin-todo/getTodoGroupData', {
      group_id: groupId,
    })
      .then(response => {
        const { group_info, user } = response.data.data

        if (!reset) {
          groupInfo.value = syncObject(blankGroupData, group_info)
          userArray.value = user.data
        } else if (reset.includes('user')) {
          userArray.value = user.data
        } else if (reset.includes('info')) {
          groupInfo.value = syncObject(blankGroupData, group_info)
        }

        isBusy.value = false
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  return {
    isBusy,
    groupInfo,
    userArray,
    getTodoGroupData,
    setTodoGroupUserData,
  }
}

export const useUserModal = () => {
  const tableData = ref([])
  const isTableBusy = ref(true)

  const {
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
  } = useTableComponent()

  const {
    useHttpCodeAlert,
  } = useAlert()

  tableColumns.value = [
    { label: '#編號', key: 'id' },
    { label: '使用者', key: 'name' },
    { label: '部門', key: 'department' },
    { label: '設定', key: 'use' },
    { label: '動作', key: 'actions' },
  ]

  const statusOptions = [{
    label: '停用', value: 0,
  }, {
    label: '啟用', value: 1,
  }]

  const setUserDelete = (...arg) => store.dispatch('admin-user-modal/setUserDelete', ...arg)
  const setUserCreate = (...arg) => store.dispatch('admin-user-modal/setUserCreate', ...arg)
  const setUserUpdate = (...arg) => store.dispatch('admin-user-modal/setUserUpdate', ...arg)

  const getUserListData = () => {
    isTableBusy.value = true
    store.dispatch('admin-user-modal/getUserList', {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sort: sortBy.value,
      order: isSortDirDesc.value ? 'desc' : 'asc',
      offset: dataMeta.value.from,
      _: Date.now(),
    })
      .then(response => {
        const { data, total } = response.data.data
        tableData.value = data
        totalNum.value = total
        isTableBusy.value = false
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  const refetchTable = () => {
    if (currentPage.value !== 1) {
      currentPage.value = 1
    } else getUserListData()
  }

  watch([currentPage, perPage, searchQuery], () => {
    getUserListData()
  })

  return {
    tableData,
    isTableBusy,
    statusOptions,
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    refetchTable,

    setUserDelete,
    setUserCreate,
    setUserUpdate,
    getUserListData,
    useHttpCodeAlert,
  }
}

export const useTodoLineChart = () => {
  const {
    departmentsOptions,
  } = useTodoSetting()

  const {
    searchDepartment,
  } = useTodoList()

  const {
    useHttpCodeAlert,
  } = useAlert()

  const monthOptions = [
    { id: 1, name: '一月' },
    { id: 1, name: '二月' },
    { id: 1, name: '三月' },
    { id: 1, name: '四月' },
    { id: 1, name: '五月' },
    { id: 1, name: '六月' },
    { id: 1, name: '七月' },
    { id: 1, name: '八月' },
    { id: 1, name: '九月' },
    { id: 1, name: '十月' },
    { id: 1, name: '十一月' },
    { id: 1, name: '十二月' },
  ]

  const showChart = ref(false)
  const isBusy = ref(true)
  const isTableBusy = ref(false)
  const timeRange = ref(null)
  const departmentChartData = ref(null)
  const monthChartData = ref(null)

  const themeColors = ['#65d8ff', '#826af9', '#ffbb53', '#e83e8c', '#37deb2', '#419fd9', '#f8d3ff',
    '#ff7f00', '#d2b0ff', '#ff0000', '#00ffff', '#b8c2cc', '#00ff7f', '#ff5b52', '#ffff00']

  const blankChartData1 = {
    options: {
      chart: {
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      colors: themeColors,
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'straight',
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
      },
      xaxis: {
        categories: [],
      },
    },
    series: [],
  }
  const formatDateRange = dateRange => {
    if (dateRange === null) {
      return ''
    }
    // 切分日期範圍
    const [startDate, endDate] = dateRange.split(' to ')

    // 解析開始日期
    const startDateObj = new Date(startDate)
    const startYear = startDateObj.getFullYear()
    const startMonth = String(startDateObj.getMonth() + 1).padStart(2, '0')

    // 解析結束日期
    const endDateObj = new Date(endDate)
    const endYear = endDateObj.getFullYear()
    const endMonth = String(endDateObj.getMonth() + 1).padStart(2, '0')

    // 返回格式化的日期範圍
    return {
      startTime: `${startYear}-${startMonth}`,
      endTime: `${endYear}-${endMonth}`,
    }
  }

  // (初始化)時間
  const today = new Date()
  const threeMonthsAgo = new Date(today.getFullYear(), today.getMonth() - 3, 1)
  const endDate = new Date(today.getFullYear(), today.getMonth(), 0)
  timeRange.value = `${threeMonthsAgo} to ${endDate}`

  const getMetaListData = callback => {
    store.dispatch('api/getMetaList', { key: 'departments' })
      .then(response => {
        const { departments } = response.data.data
        departmentsOptions.value = departments
        callback()
      })
  }

  const getDepartmentLineChart = () => {
    const resolveTime = formatDateRange(timeRange.value)
    if (!resolveTime) return
    if (isTableBusy.value) return
    isTableBusy.value = true
    showChart.value = true
    store.dispatch('admin-todo/getLineChartDepartmentList', {
      department_id: searchDepartment.value,
      start_time: resolveTime.startTime,
      end_time: resolveTime.endTime,
    })
      .then(response => {
        const { dateData, totalData } = response.data.data
        const resolveChartData = JSON.parse(JSON.stringify(blankChartData1))
        resolveChartData.options.xaxis.categories = dateData // ['2024/01', '2024/02', '2024/03', '2024/04', '2024/05', '2024/06', '2024/07', '2024/08', '2024/09', '2024/10', '2024/11', '2024/12']

        const dataArray = []
        const keys = Object.keys(totalData)
        // const themeColors = generateColors(keys.length)
        for (let i = 0; i < keys.length; i += 1) {
          const key = keys[i]
          const value = totalData[key]
          dataArray.push({ department: key, data: value })
        }

        const seriesData = dataArray.map(item => {
          const targetObject = departmentsOptions.value.find(obj => obj.id === parseInt(item.department, 10))
          const resolve = {
            name: targetObject ? targetObject.name : item.department,
            data: item.data,
          }
          return resolve
        })
        resolveChartData.series = seriesData
        departmentChartData.value = resolveChartData
        isTableBusy.value = false
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  return {
    showChart,
    isBusy,
    isTableBusy,
    departmentChartData,
    monthChartData,

    searchDepartment,
    timeRange,
    monthOptions,
    departmentsOptions,
    getMetaListData,
    getDepartmentLineChart,
  }
}
